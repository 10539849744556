<template>
  <div>
    <v-card>
      <div class="pa-5 mb-5">
        <v-card-title
          primary-title
          class="title"
        >
          Réception des adhésions en ligne
        </v-card-title>
        <v-card-title> Sélectionner un mode de paiement pour effectuer la validation</v-card-title>
        <div>
          <v-row v-if="isUserNational">
            <v-col cols="12">
              <v-autocomplete
                :items="filterDepartements"
                label="Département"
                :item-text="(item) => `${item.code} - ${item.nom}`"
                item-value="id"
                @change="changeDepartement"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <v-select
                v-model="typePaiementSelected"
                :items="typesPaiements"
                label="Mode de paiement"
                @change="changeFiltre"
              />
            </v-col>
          </v-row>

          <v-row v-if="showInclusEnAttente">
            <v-col cols="12">
              <v-switch
                v-model="inclusEnAttente"
                label="Inclure les paiements mis en attente"
                @change="changeFiltre"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <v-btn
                color="primary"
                :disabled="selected.length === 0"
                @click="validerSelection()"
              >
                Valider
              </v-btn>
              <v-btn
                v-if="showInclusEnAttente"
                class="ml-4"
                color="primary"
                :disabled="selected.length === 0"
                @click="mettreEnAttenteSelection()"
              >
                Mettre en attente
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </div>
    </v-card>

    <div>
      <v-data-table
        v-model="selected"
        light
        :headers="headers"
        :items="validationAdhesionsEnLigneFormatted"
        item-key="paiement_id"
        show-select
        class="elevation-3"
        :loading="loading"
        loading-text="Chargement en cours"
        no-data-text="Aucun parent à valider"
        sort-by="conseil_local.nom"
        :items-per-page="50"
        :footer-props="{
          itemsPerPageText: 'Parents par page:',
          itemsPerPageOptions: [20, 50, 100, 200],
        }"
      >
        <v-progress-linear
          v-if="loading"
          v-slot:progress
          color="blue"
        />

        <template v-slot:no-data>
          Aucun parent à valider
        </template>

        <template v-slot:header.data-table-select>
          <v-checkbox
            :input-value="selectAllValue"
            :indeterminate="selectAllIndeterminate"
            @click.stop="toggleAll"
          />
        </template>

        <template v-slot:item.actions="{ item }">
          <v-menu offset-y>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on">
                more_vert
              </v-icon>
            </template>
            <v-list>
              <v-list-item @click="validerParent(item)">
                <v-list-item-title>Valider</v-list-item-title>
              </v-list-item>
              <v-list-item @click="checkCancel(item)">
                <v-list-item-title>Annuler</v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="showInclusEnAttenteItem(item)"
                @click="mettreEnAttenteParent(item)"
              >
                <v-list-item-title>Mettre en attente</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>

        <template v-slot:item.parent_principal="{ item }">
          {{ item.parent_principal.nom }} {{ item.parent_principal.prenom }}
        </template>

        <template v-slot:item.date_operation="{ item }">
          {{ item.date_operation | formatDate }}
        </template>

        <template v-slot:item.conseil_local.nom="{ item }">
          <v-select
            v-model="item.conseil_local"
            :items="item.conseils_locaux_etablissements_enfants"
            item-text="nom"
            return-object
            @change="changeConseilLocal"
          />
        </template>

        <template v-slot:item.montant="{ item }">
          <div style="white-space: nowrap;">
            <div>
              National : {{ item.montant_national | currency }}
            </div>
            <div>
              Départ. : {{ item.montant_departemental | currency }}
            </div>
            <div
              v-if="item.montant_local > 0"
            >
              Local : {{ item.montant_local | currency }}
            </div>
            <div>
              Total : {{ item.montant_total | currency }}
            </div>
          </div>
        </template>
      </v-data-table>

      <v-progress-linear
        v-if="loading"
        :indeterminate="true"
      />
    </div>
    <ConfirmedModal
      v-if="showConfirmationPopup && cancelItem"
      :sentence="`Êtes-vous sûr de vouloir annuler l'adhésion de ${cancelItem.parent_principal.nom} ${cancelItem.parent_principal.prenom} ?`"
      btn-action="Annuler l'adhésion"
      btn-cancel="Retour"
      @close="checkCancel()"
      @confirmed="annulerParent(cancelItem)"
    />
  </div>
</template>

<script>
import ConfirmedModal from '../reusableComponents/confirmedModal';
import { mapGetters, mapActions } from 'vuex';
import selectAllMixin from '../../mixins/vTableDataSelectAll';

export default {
  name: 'ValidationAdhesionsEnLigne',
  components: {
    ConfirmedModal,
  },
  mixins: [selectAllMixin],
  data: () => ({
    showConfirmationPopup: false,
    cancelItem: null,
    headers: [
      {
        text: 'Actions',
        sortable: false,
        value: 'actions',
      },
      {
        text: 'Code',
        sortable: true,
        value: 'code',
      },
      {
        text: 'Parent',
        sortable: true,
        value: 'parent_principal',
        sort: (parenta, parentb) => {
          let result = parenta.nom.localeCompare(parentb.nom);
          if (result === 0) {
            return parentb.prenom.localeCompare(parentb.prenom);
          }
          return result;
        },
      },
      {
        text: 'Email',
        sortable: true,
        value: 'email',
      },
      {
        text: 'Date',
        sortable: true,
        value: 'date_operation',
      },
      {
        text: 'Conseil Local',
        sortable: true,
        value: 'conseil_local.nom',
      },
      {
        text: 'Montant',
        sortable: true,
        value: 'montant',
      },
      {
        text: 'Mode de paiment',
        sortable: false,
        value: 'type_paiement',
      },
    ],
    typesPaiements: [
      { value: 'PAYPAL', text: 'Paypal / CB' },
      { value: 'HIPAY', text: 'HiPay / CB' },
      { value: 'MONEY_TIGO', text: 'OVRI / CB' },
      { value: 'CHEQUE', text: 'Chèque' },
      { value: 'VIREMENT', text: 'Virement' },
    ],
    currentCdpeId: 0,
    typePaiementSelected: null,
    inclusEnAttente: false,
  }),
  computed: {
    ...mapGetters({
      validationAdhesionsEnLigne: 'getValidationAdhesionsEnLigne',
      loading: 'getWorkflowLoading',
    }),
    ...mapGetters('session', {
      isUserNational: 'isUserNational',
      isUserDepartemental: 'isUserDepartemental',
      getPerimetreUser: 'getPerimetreUser',
    }),
    ...mapGetters('cdpe', {
      filterDepartements: 'getFilterDepartements',
    }),
    items() {
      return this.validationAdhesionsEnLigneFormatted;
    },
    showInclusEnAttente() {
      return ['CHEQUE', 'VIREMENT'].includes(this.typePaiementSelected);
    },
    validationAdhesionsEnLigneFormatted() {
      return this.validationAdhesionsEnLigne.map(parent => {
        parent.conseils_locaux_etablissements_enfants.forEach(conseil => {
          conseil.parentId = parent.foyer_id;
        });
        parent.conseil_local = parent.conseils_locaux_etablissements_enfants.reduce(
          (nomConseil, conseil) =>
            parent.cpe_principal_id === conseil.perimetre_id ? conseil.nom : nomConseil,
          '',
        );
        parent.montant_national = parent.montant_adhesion_national + parent.montant_abonnement;
        parent.montant_departemental = parent.montant_adhesion_departemental + parent.montant_don;
        parent.montant_local = parent.montant_adhesion_local;
        parent.montant_total = parent.montant_national + parent.montant_departemental + parent.montant_adhesion_local;

        return parent;
      });
    },
  },
  created() {
    if (this.isUserDepartemental) {
      this.currentCdpeId = this.getPerimetreUser.perimetre_id;
    }
  },
  methods: {
    ...mapActions([
      'loadValidationAdhesionsEnLigne',
      'validateAdhesionsEnLigne',
      'cancelAdhesionsEnLigne',
      'mettreEnAttenteAdhesionsEnLigne',
      'updateConseilLocalParent',
    ]),
    checkCancel(item) {
      if (item) {
        this.cancelItem = item;
      } else {
        this.cancelItem = null;
      }
      this.showConfirmationPopup = !this.showConfirmationPopup;

    },
    changeDepartement(cdpeId) {
      this.currentCdpeId = cdpeId;
      this.changeFiltre();
    },
    changeFiltre() {
      if (this.currentCdpeId && this.typePaiementSelected) {
        this.loadValidationAdhesionsEnLigne({
          cdpeId: this.currentCdpeId,
          modesPaiement: [this.typePaiementSelected],
          inclusEnAttente: this.inclusEnAttente,
        });
        this.selected = [];
      }
    },
    changeConseilLocal(conseil) {
      this.updateConseilLocalParent({
        parentId: conseil.parentId,
        conseilLocalId: conseil.perimetre_id,
      });
    },
    validerParent(parent) {
      this.validateAdhesionsEnLigne([parent.paiement_id]).finally(() => this.changeFiltre());
    },
    annulerParent(parent) {
      this.cancelAdhesionsEnLigne([parent.paiement_id]).finally(() => this.changeFiltre(), this.checkCancel());
    },
    validerSelection() {
      this.validateAdhesionsEnLigne(this.selected.map(parent => parent.paiement_id)).finally(() =>
        this.changeFiltre()
      );
    },
    mettreEnAttenteParent(parent) {
      this.mettreEnAttenteAdhesionsEnLigne([parent.paiement_id]).finally(() => this.changeFiltre());
    },
    mettreEnAttenteSelection() {
      this.mettreEnAttenteAdhesionsEnLigne(
        this.selected.map(parent => parent.paiement_id)
      ).finally(() => this.changeFiltre());
    },
    showInclusEnAttenteItem(parent) {
      return ['CHEQUE', 'VIREMENT'].includes(parent.type_paiement);
    },
  },
};
</script>
