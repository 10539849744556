<template>
  <div>
    <ReceptionAdhesionsEnLigne />
  </div>
</template>

<script>
import ReceptionAdhesionsEnLigne from '../../components/workflow/ReceptionAdhesionsEnLigne';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'ReceptionAdhesionsEnLigneContainer',
  components: {
    ReceptionAdhesionsEnLigne,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters('session', {
      getPerimetreUser: 'getPerimetreUser',
      isUserNational: 'isUserNational',
      getUserCdpeId: 'getUserCdpeId',
    }),
  },
  created() {
    if (this.isUserNational) {
      this.loadFilterDepartements();
    }
  },

  methods: {
    ...mapActions('cdpe', [
      'loadFilterDepartements',
    ]),
  },
};
</script>
